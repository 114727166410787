<template>
  <div id="app">
    <div class="mobile-wrapper" v-if="isMobile">
      <CameraFeed
        v-if="cameraShow && assetsLoaded"
        :loadedCallback="onCameraLoaded"
        ref="cameraFeed"
      />
      <Scenary 
        v-if="xrLoaded && assetsLoaded" 
        :assets="assets"
        :xr="xr"
      />
      <img class="logo-header" alt="Bradesco" :src="require('@/assets/images/logo-header.png')"/>
      <div class="preloader-wrapper">
        <div class="loader">Carregando...</div>
      </div> 
      <img class="powered-by" alt="Bradesco" :src="require('@/assets/images/8thwall_horizontal_white.png')"/>
      <small class="developed-by">Development Buu Digital</small>
    </div>
    <div class="desk-wrapper" v-if="!isMobile">
      <Desktop />
    </div>

  </div>
</template>

<script>
import { Preloader } from "./utils/loaders/Preloader";
import { TweenMax, Quad } from 'gsap';
import CameraFeed from "@/components/CameraFeed";
import Scenary from "@/components/Scenary";
import Desktop from "@/components/Desktop";

export default {
  name: 'App',
  components: {
    CameraFeed,
    Scenary,
    Desktop
  },
  watch: {
    xrLoaded: (newVal, oldVal) => {
      // console.log('xrLoaded', newVal)
    }
  },
  data() {
    return {
      isMobile: navigator.userAgent.toLowerCase().match(/mobile/i),
      isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
      cameraShow: true,
      assetsLoaded: false,
      xrLoaded: false,
      xr: {},
      assets: {},
    };
  },
  mounted() {
    if (this.isMobile) {
      this.loadQueue()
      // clearTimeout(this.lowVersionTimer)
      // this.lowVersionTimer = setTimeout(()=>{
      //   clearTimeout(this.lowVersionTimer)
      //   window.location.href = LOW_URL
      // }, LOW_LIMIT_TIME)
    }
  },
  methods: {
    loadQueue(){
      this.preloader = new Preloader('')
      this.preloader.debug = false
      this.preloader.addListener('onComplete', (details) => this.loadComplete(details) );
      this.preloader.addListener('onProgress', (details) => this.loadProgress(details) );
      this.preloader.queue([
        // models
        // { name: 'vagalume', url: `./models/vagalume.gltf`, type: 'gltf' },
        { name: 'vagalume', url: `./models/final.gltf`, type: 'gltf' },
        { name: 'logo', url: `./models/logo_new.glb`, type: 'gltf' },
        // textures
        { name: 'marker', url: `./images/marker.png`, type: 'texture' },
        { name: 'spark', url: `./images/spark.png`, type: 'texture' },
        { name: 'star', url: `./images/star.png`, type: 'texture' },
        { name: 'star_grad', url: `./images/star-grad.png`, type: 'texture' },
        { name: 'logo_star_grad', url: `./images/logo-star-grad.png`, type: 'texture' },
        { name: 'logo_color', url: `./images/light_color.jpg`, type: 'texture' },
        { name: 'logo_alpha', url: `./images/light.jpg`, type: 'texture' },
        // HDR
        { name: 'hdr', url: `./images/cayley_interior_1k.hdr`, type: 'hdr'},
      ]);
    },
    loadProgress(details){  
      // console.log('Preloader loadProgress: ', details);
    },

    loadComplete(details){  
      // console.log('Preloader complete: ', details);
      clearTimeout(this.lowVersionTimer)
      this.assets = details.data
      this.assetsLoaded = true
    },
    isInAppBrowser() {
      const ua = navigator.userAgent || navigator.vendor || window.opera;
      return (
        ua.indexOf("FBAN") > -1 ||
        ua.indexOf("FBAV") > -1 ||
        ua.indexOf("Instagram") > -1
      );
    },
    getBrowserCompatible() {
      const ua = navigator.userAgent.toLowerCase();
      return this.isIOS && ua.match("crios");
    },
    onCameraLoaded({ scene, camera, renderer }) {
      this.xr = { scene, camera, renderer }
      this.$nextTick(()=>{
        // console.log('onCameraLoaded')
        this.xrLoaded = true
        TweenMax.staggerTo(['.powered-by', '.developed-by'], 0.6, { autoAlpha: 0, delay: 1, ease: Quad.easeInOut }, 0.2)
        TweenMax.to('.preloader-wrapper', 0.4, { autoAlpha: 0, delay: 1, ease: Quad.easeInOut, onComplete:()=>{
          TweenMax.set('.preloader-wrapper', { display: 'none'})
        }})
      })
    },
    
  },
}
</script>

<style lang="scss">
#app {
  @include set-size(100%, 100%);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;

  .powered-by {
    @include set-size(160px, auto);
    @include center-x(absolute);
    bottom: 8vh;
    z-index: 10;
  }

  .developed-by {
    @include center-x(absolute);
    bottom: 4vh;
    z-index: 10;
    font-family: $regular;
    @include font-size(13);
    color: #fff;
  }

  .logo-header{
    @include set-size(20vw, auto);
    position: absolute;
    top: 2.5vw;
    right: 2.5vw;
    z-index: 100;
    mix-blend-mode: screen;
    max-width: 120px;

    @include minWidth(500) {
      @include set-size(20vh, auto);
    }
  }

  .preloader-wrapper {
    width: 100%;
    height: 100%;
    @include center(absolute);
    z-index: 10;
    background-color: rgba(0,0,0,0.9);

    .loader,
    .loader:before,
    .loader:after {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: load7 1.8s infinite ease-in-out;
      animation: load7 1.8s infinite ease-in-out;
    }
    .loader {
      color: #acee15;
      font-size: 10px;
      text-indent: -9999em;
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
      @include center(absolute);
      margin-top: -3vh;
    }
    .loader:before,
    .loader:after {
      content: '';
      position: absolute;
      top: 0;
    }
    .loader:before {
      left: -3.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    .loader:after {
      left: 3.5em;
    }
    @-webkit-keyframes load7 {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }
    @keyframes load7 {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }

  }

  .rule-of-thirds {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.3;
  }

  .rule-of-thirds.is--disabled {
    opacity: 0;
  }

  .rule-of-thirds__axis {
    position: absolute;
  }

  .rule-of-thirds__axis--x,
  .rule-of-thirds::before {
    top: calc(28% + 2px);

    height: 2px;
    width: 100vw;
    background-color: blue;
  }

  .rule-of-thirds__axis--x:nth-child(2) {
    top: calc(70% + 2px);
  }

  .rule-of-thirds__axis--y,
  .rule-of-thirds::after {
    left: calc(28% + 2px);

    height: 100vh;
    width: 2px;
    background-color: red;
  }

  .rule-of-thirds__axis--y:nth-child(3) {
    left: calc(70% + 2px);
  }

  .rule-of-thirds::after {
    left: 0;
    right: 0;
    margin: auto;
  }

  .rule-of-thirds::before {
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .rule-of-thirds::before,
  .rule-of-thirds::after {
    content: "";
    position: absolute;
    background-color: green;
  }

  
}

</style>
