
export const placegroundScenePipelineModule = (loadedCallback) => {
    return {
      name: 'placeground',
      onStart: ({canvas, canvasWidth, canvasHeight}) => {
        const {scene, camera, renderer} = XR8.Threejs.xrScene()

        loadedCallback({scene, camera, renderer})
  
        XR8.XrController.updateCameraProjectionMatrix({
          origin: camera.position,
          facing: camera.quaternion,
        })
      },
    }
  }