<template>
  <section class="camera-feed">
    <canvas ref="canvas" id="camera-feed-stage" class="camera-feed__canvas"></canvas>
  </section>
</template>

<script>
window.THREE = require("three");
import { placegroundScenePipelineModule } from '@/classes/placegroundPipeline'
export default {
  name: "camera-feed",
  props: ["loadedCallback"],
  components: {},
  data() {
    return {
      loading: true,
      isMobile: navigator.userAgent.toLowerCase().match(/mobile/i)
    };
  },
  mounted() {
    window.addEventListener("resize", e => {
      this.$refs.canvas.width = window.innerWidth
      this.$refs.canvas.height = window.innerHeight
    });

    this.$nextTick(() => {
      window.XR8
        ? this.load()
        : window.addEventListener("xrloaded", this.load);
      // console.log("xr_started");
    });
  },

  methods: {
    load() {
      // console.log(`CameraFeed load`)
      XR8.addCameraPipelineModules([
        XR8.GlTextureRenderer.pipelineModule(),
        XR8.Threejs.pipelineModule(),
        XR8.XrController.pipelineModule(),
        {
          name: "camerastartupmodule",
          onCameraStatusChange: ({ status }) => {
            if (status == "requesting") {
              // console.log("camera requesting");
            } else if (status == "hasStream") {
            } else if (status == "hasVideo") {
              this.loading = false;
            } else if (status == "failed") {
              // console.log("camera falied");
            }
          }
        },
        placegroundScenePipelineModule(this.loadedCallback)
      ]);

      this.run();
    },
    run() {
      this.$refs.canvas.width = window.innerWidth
      this.$refs.canvas.height = window.innerHeight
      XR8.run({
        canvas: this.$refs.canvas,
        allowedDevices: XR8.XrConfig.device().ANY,
      });
    },
    pause() {
      if (!XR8.isPaused()) {
        XR8.pause();
      }
    },
    resume() {
      if (XR8.isPaused()) {
        XR8.resume();
      }
    }
  }
};
</script>

<style lang="scss">
.camera-feed {
  @include set-size(100%, 100%);
  z-index: 0;
  overflow: hidden;
  
  .camera-feed__canvas {
    @include set-size(100%, 100%);
    overflow: hidden;
    // transform: scaleX(-1);
  }
}
</style>