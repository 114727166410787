<template>
  <div class="experiment dark">
    <canvas id="canvas-renderer" ref="canvasRenderer"></canvas> 
    <canvas id="canvas-renderer-parts" ref="canvasRendererParts"></canvas> 
    
    <div class="tap-instructions">
      <p class="tap-instructions__text">
        Aponte o celular pra frente e toque na tela para iniciar.
      </p>
    </div>

    <div class="buttons-container">
      <img class="buttons-recenter" alt="Clique para centralizar" @click="recenter" :src="require('@/assets/images/recenter.png')"/>
    </div>
    <footer class="footer">
      <p class="footer__text">Arraste o dedo para espalhar o brilho, clique nos personagens<br>para interação e use a mira para levá-los a novos ambientes.</p>
    </footer>
  </div>
</template>ahsim

<script>
const THREE = require('three');
const Hammer = require('hammerjs');
import { TweenMax, Quad, Power0 } from 'gsap';
import * as dat from 'dat.gui';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import SPE from '@/classes/SPE';
import { particleMaterial } from '@/shaders/particlesShader';
import { GammaCorrectionShader } from '../shaders/gammaCorrection';
// const recenterIcon = require('@/assets/images/recenter.png')
export default {
  props: ['assets', 'xr'],
  name: 'Scenary',
  data(){
    return {
      minScale: new THREE.Vector3(0.001, 0.001, 0.001),
      maxScale: new THREE.Vector3(0.01, 0.01, 0.01),
      currentScale: 0.001,
      renderer: null,
      camera: null,
      scene: null,
      composer: null,
      surface: null,
      raycaster: null,
      envMap: null,
      scale: 0.001,
      tapPosition: null,
      isDragging: false,
      placed: false,
      isBaseGrid: false,
      startTouchPosition:  new THREE.Vector2(50,50),
      modelsInTheScene: [],
      brightAss: false,
    }
  },
  mounted(){
    this.clock = new THREE.Clock()
    this.setupThree()  
    this.setupSurface()
    this.setupListeners()
    this.setupGestures()
    this.setupModel()
    this.setupParticlesTrail()
    this.render() 

  },
  methods: {
    setupThree(){
      this.camera = this.xr.camera
      this.camera.position.set(0, 3, 0)

      this.scene = new THREE.Scene();

      let pixelRatio = window.devicePixelRatio || 0

      this.renderer = new THREE.WebGLRenderer( { canvas: this.$refs.canvasRenderer, alpha: true } );
      this.renderer.setPixelRatio( pixelRatio );
      this.renderer.setSize( window.innerWidth, window.innerHeight );
      // this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.autoClear = false;
      // this.renderer.shadowMap.enabled = true;

      this.addPost(pixelRatio)

      this.raycaster = new THREE.Raycaster();

      this.scene.add(new THREE.AmbientLight( 0x404040, 5 ))

      this.pmremGenerator = new THREE.PMREMGenerator( this.renderer );
      this.pmremGenerator.compileEquirectangularShader();
      this.envMap = this.pmremGenerator.fromEquirectangular( this.assets.hdr ).texture;
    },
    addPost(pixelRatio){
      const renderPass = new RenderPass( this.scene, this.camera );
      this.fxaaPass = new ShaderPass( FXAAShader );

      this.fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( window.innerWidth * pixelRatio );
      this.fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( window.innerHeight * pixelRatio );

      const gammaCorrectionPass = new ShaderPass( GammaCorrectionShader );

      this.composer = new EffectComposer( this.renderer );
      this.composer.addPass( renderPass );
      this.composer.addPass( this.fxaaPass );
      this.composer.addPass( gammaCorrectionPass );
    },
    setupSurface(){
      this.surface = new THREE.Mesh(
        new THREE.PlaneGeometry( 200, 150, 1, 1 ), 
        new THREE.MeshPhongMaterial({
          color: 0xffffff,
          opacity: 0,
          transparent: true,
          side: THREE.DoubleSide,
        })
      )
      this.surface.rotateX(- Math.PI / 2)
      this.surface.position.set(0, -5, -50)
      this.surface.name = 'ground'
      this.scene.add(this.surface)

      const baseGridMesh = new THREE.Mesh(
        new THREE.PlaneGeometry( 1.5, 1.5, 1, 1 ), 
        new THREE.MeshPhongMaterial({
          map: this.assets.marker,
          opacity: 1,
          transparent: true,
          side: THREE.DoubleSide,
        })
      )
      baseGridMesh.rotateX(- Math.PI / 2)

      if (this.isBaseGrid) {
        this.baseGrid = new THREE.Group()
        this.baseGrid.position.set(0, 0, 0)
        this.baseGrid.add(baseGridMesh)
        this.scene.add(this.baseGrid)
      }
    },
    setupModel(){
      this.animationMixers = []
      this.model = this.assets.vagalume.scene
      let anims =  this.assets.vagalume.animations
      if (anims && anims.length > 0) {
          let _mixer = new THREE.AnimationMixer(  this.model );
          anims.map(_a => {
            _mixer.clipAction( _a ).play();
          })
          this.animationMixers.push(_mixer)
      }

      this.model.traverse(child => {
        if (child.isMesh) {
          
          child.material.envMap = this.envMap
          // child.receiveShadow = true
          child.castShadow = true
          child.material.roughness = 0.5
          child.material.metalness = 0.3

          if (child.name == "Luna_1" || child.name == "Default_Luna_3") {
            child.visible = false
          }
          if (child.name.indexOf('Lucio') > -1 && child.isSkinnedMesh) {
            child.material.emissiveIntensity = 0; 
            this.modelsInTheScene.push(child)
          }

        }
      })
      this.model.visible = false
      this.model.scale.set(0.01, 0.01, 0.01)
      this.scene.add(this.model)

      this.assets.hdr.dispose();
      this.pmremGenerator.dispose();

      // let directionalLight = new THREE.DirectionalLight(0xfffffff,2);
      // directionalLight.position.set(0, 10, 0);
      // directionalLight.castShadow = true;
      // directionalLight.shadow.mapSize.width = 512;  
      // directionalLight.shadow.mapSize.height = 512; 
      // directionalLight.shadow.camera.near = 0.5;
      // directionalLight.shadow.camera.far = 500 
      // const d = 10; 
      // directionalLight.shadow.camera.left = - d; 
      // directionalLight.shadow.camera.right = d; 
      // directionalLight.shadow.camera.top = d; 
      // directionalLight.shadow.camera.bottom = - d

      // this.scene.add(directionalLight)

      // const planeGeometry = new THREE.PlaneBufferGeometry( 2000, 2000 );
      // planeGeometry.rotateX( - Math.PI / 2 );
      // const planeMaterial = new THREE.ShadowMaterial( { opacity: 0.2 } );

      // const plane = new THREE.Mesh( planeGeometry, planeMaterial );
      // plane.position.y = 1;
      // plane.receiveShadow = true;
      // this.scene.add( plane );
    },
    setupLogo(x, y, z){
      this.logo = this.assets.logo.scene
      let anims =  this.assets.logo.animations

      if (anims && anims.length > 0) {
          let _mixer = new THREE.AnimationMixer(  this.logo );
          _mixer.name = 'logoMixer'
          anims.map(_a => {
            _mixer.clipAction( _a ).play();
          })
          this.animationMixers.push(_mixer)
      }

      // const planeMaterial = new THREE.MeshBasicMaterial({
      //   map: this.assets.star_grad,
        // color: 0xff0000,
        // alphaMap: this.assets.logo_alpha,
        // envMap: this.envMap,
        // transparent: true,
        // blending: THREE.AdditiveBlending
      // })
      this.logo.traverse(child => {
        if (child.isMesh) {
          child.material.envMap = this.assets.hdr
          // child.material.map = this.assets.logo_star_grad
          // child.material.emissiveMap = this.assets.logo_star_grad
          // child.material.alphaMap = this.assets.logo_star_grad
          // child.material = planeMaterial
        }
      })
      this.logo.position.set(x, y, z)
      this.logo.scale.set(0.3, 0.3, 0.3)
      this.logo.lookAt(this.camera.position)
      this.scene.add(this.logo)
      
    
    },
    setupListeners(){
      document.querySelector('.tap-instructions').addEventListener('touchstart', this.placeModel)
      this.$refs.canvasRendererParts.addEventListener('touchmove', this.touchDragMove)
      this.$refs.canvasRendererParts.addEventListener('touchend', this.touchDragEnd)
      this.$refs.canvasRendererParts.addEventListener('touchstart', this.touchDragStart)
      // document.getElementById('camerafeed').addEventListener('touchstart', this.recenterScene)
      window.addEventListener('resize', this.onResizeWindow)
    },
    setupGestures(){
      this.tapPosition = new THREE.Vector2()

      this.scale = this.currentScale
      const el = document.getElementById('canvas-renderer-parts')
      const ham = new Hammer( el, { domEvents: true });
     
      ham.get('pinch').set({ enable: true });
      ham.on( "pinch", ( e ) => {
        // this.pinchActive = true
        // const pinchScale = this.scale + this.scale * (e.scale - 1) * 0.4;
          
        // if((pinchScale >= this.minScale.x && pinchScale <= this.maxScale.x)){
        //   if(this.model != null)
        //   this.model.scale.set(pinchScale, pinchScale, pinchScale);
        // }
      });
      
      // ham.on( "pinchend", ( e ) => {
        // let pinchScale = this.scale + this.scale * (e.scale - 1) * 0.4;
              
        // if(pinchScale < this.minScale.x){
        //   pinchScale = this.minScale.x;
        // }
        // if(pinchScale > this.maxScale.x){
        //   pinchScale = this.maxScale.x;
        // }

        // this.scale = pinchScale;

        // this.pinchActive = false
      // });

      // ham.get('rotate').set({ enable: true });
      // ham.on( "rotatemove", ( e ) => {
      //   this.pinchActive = true
      //   let direction = e.direction
      //   let distance = e.distance
      //   if ((direction == 2 || direction == 4) && distance > 40) {
      //     let rotY = this.iOS ? 0.5 : 1.0
      //     if (direction == 2) {
      //       rotY = this.iOS ? -0.5 : -1.0
      //     }
      //     let currentRot = new THREE.Vector3(this.model.rotation.x, this.model.rotation.y, this.model.rotation.z)
      //     let newRot = new THREE.Vector3(this.model.rotation.x, this.model.rotation.y + rotY, this.model.rotation.z)
      //     currentRot.lerp(newRot, 0.1)
      //     this.model.rotation.set(currentRot.x, currentRot.y, currentRot.z)
      //   }
      // });

    },
    setupParticlesTrail () {     
      this.sceneParts = new THREE.Scene();
      this.cameraParts = new THREE.PerspectiveCamera(28, window.innerWidth/window.innerHeight, 0.1, 10000);
      this.cameraParts.position.z = 10;
      this.cameraParts.lookAt( this.sceneParts.position );

      this.rendererParts = new THREE.WebGLRenderer({canvas: this.$refs.canvasRendererParts, alpha: true});
      this.rendererParts.setSize( window.innerWidth, window.innerHeight );
      this.rendererParts.autoClear = false
      let color = new THREE.Color("hsl(116, 100%, 50%)");

      this.mouseVector = new THREE.Vector3()
      this.particleGroup = new SPE.Group({
        texture: {
          value: this.assets.star_grad
        },
        maxParticleCount: 1500,
        // blending: THREE.AdditiveBlending
      });
      

      this.emitter = new SPE.Emitter({
        maxAge: 1,
        position: {
          value: new THREE.Vector3(0, 0, 0),
          // radius: 1,
          spread: new THREE.Vector3( 0.02, 0.02, 0.02 ),
        },

        acceleration: {
          value: new THREE.Vector3(0, 1, -1),
          spread: new THREE.Vector3(2, 2, 2)
        },

        velocity: {
          value: new THREE.Vector3( 0.5, 0.5, 0.5 ),
          distribution: SPE.distributions.SPHERE
        },

        // color: {
        //   value: [ new THREE.Color( color.r, color.g, color.b ) ],
        //   spread: new THREE.Vector3(0, 0, 0),
        // },
        size: {
          value: 0.4
        },
        particleCount: 500
      });

      this.particleGroup.addEmitter( this.emitter );
      this.sceneParts.add( this.particleGroup.mesh );
    },
    addParticleLights(config, amount, radius, oscilationY){
      this.particleSystems = []
      this.totalParticles = 0
      this.geometryParticlesLights = new THREE.BufferGeometry();
      this.positions = [];
      this.colors = [];
      this.sizes = [];
      let color = new THREE.Color("hsl(116, 100%, 50%)");

      for ( let i = 0; i < amount; i ++ ) {

          let pos = {
              x: config.center.x + Math.sin(i) * (Math.random() * radius),
              // y: oscilationY ? config.center.y + Math.sin(i * 0.005) : config.center.y,
              y: config.center.y + Math.random() * 10,
              z: config.center.z + Math.cos(i) * (Math.random() * radius),
          }

          
          this.positions.push( pos.x );
          this.positions.push( pos.y );
          this.positions.push( pos.z );
          
          // color.setHSL( 0.51, 1.0, 0.5 );
          // color.setHSL( 0.5, 0.2, 0.5 );
          
          this.colors.push( color.r, color.g, color.b );
          
          this.sizes.push( 0.005 );         
      }
      
      this.geometryParticlesLights.setAttribute( 'position', new THREE.Float32BufferAttribute(  this.positions, 3 ) );
      this.geometryParticlesLights.setAttribute( 'color', new THREE.Float32BufferAttribute( this.colors, 3 ) );
      this.geometryParticlesLights.setAttribute( 'size', new THREE.Float32BufferAttribute( this.sizes, 1 ).setUsage( THREE.DynamicDrawUsage ) );
      
      if (!this.particleSystem) {
          const shaderMaterial = particleMaterial(this.assets.star_grad, true)
          this.particleSystem = new THREE.Points( this.geometryParticlesLights, shaderMaterial );
          this.scene.add( this.particleSystem );
      }

      this.totalParticles += amount
    },
    touchDragMove(e) {
      if (this.pinchActive) return

      this.mouseVector.set(
          (e.changedTouches[0].clientX / window.innerWidth) * 2 - 1,
          -(e.changedTouches[0].clientY / window.innerHeight) * 2 + 1,
          0.5
      );
      
      // let distance = this.startTouchPosition.x - e.changedTouches[0].clientX
      // if (distance < 0) distance *= -1
      // if (this.firstTapToRotation) {
      //   this.firstTapToRotation = distance < 10
      // }
      // if(this.isDragging && !this.firstTapToRotation) {
      //   let rotY = 1
      //   if (e.changedTouches[0].clientX < this.previousTouchPosition.x) {
      //     rotY *= -1
      //   }
      //   let currentRot = new THREE.Vector3(this.model.rotation.x, this.model.rotation.y, this.model.rotation.z)
      //   let newRot = new THREE.Vector3(this.model.rotation.x, this.model.rotation.y + rotY, this.model.rotation.z)
      //   currentRot.lerp(newRot, this.iOS ? 0.08 : 0.2)
      //   this.model.rotation.set(currentRot.x, currentRot.y, currentRot.z)
      // }
      this.previousTouchPosition = {
        x: (e.changedTouches[0].clientX / window.innerWidth ) * 2 - 1,
        y: - (e.changedTouches[0].clientY / window.innerHeight ) * 2 + 1
      };
    },
    touchDragStart(e){
      this.startTouchPosition = {
        x: (e.changedTouches[0].clientX / window.innerWidth ) * 2 - 1,
        y: - (e.changedTouches[0].clientY / window.innerHeight ) * 2 + 1
      };
      gaTagEvent('REALIDADE AUMENTADA bradesco', 'Brilho arrasto dedo', 'clique arrasto luzes');
      this.isDragging = true
      this.firstTapToRotation = true
      this.isTap = true
    },
    touchDragEnd(e){
      this.isDragging = false
      this.firstTapToRotation = false
      this.previousTouchPosition = {
        x: e.changedTouches[0].clientX,
        y: e.changedTouches[0].clientY
      };
      gaTagEvent('REALIDADE AUMENTADA bradesco', 'Parou Brilho arrasto dedo', 'parou clique arrasto luzes');
    },
    placeModel(e){
      gaTagEvent('REALIDADE AUMENTADA bradesco', 'Vagalume 2', 'clique');
      if (this.placed) return
      e.preventDefault()
      e.stopPropagation()
      TweenMax.to('.tap-instructions', 0.4, { autoAlpha: 0, ease: Quad.easeInOut, onComplete: ()=>{
        TweenMax.set('.tap-instructions', { display: 'none' })
      }})
      if (this.isBaseGrid) {
        TweenMax.fromTo(this.baseGrid.scale, 0.4, 
          {x:1,y:1,z:1},
          {x:0.0001,y:0.0001,z:0.0001, ease: Quad.easeInOut}
        )
      }
      this.$el.classList.add('dark')
      
      TweenMax.fromTo(this.model.scale, 0.6, 
        {x:0.00001,y:0.00001,z:0.00001},
        {x:0.002,y:0.002,z:0.002, delay: 0.6, ease: Quad.easeInOut, onStart: ()=> {
          this.placed = true
          this.recenter()
          if (this.isBaseGrid) {
            this.model.position.set(this.baseGrid.position.x, 2, this.baseGrid.position.z)
          } else {
            this.model.position.set(0, 2, -1.2)
          }
          this.model.visible = true
        }, onComplete: ()=> {
          TweenMax.to('.footer', 0.6, { autoAlpha: 1, delay: 0.2, ease: Quad.easeInOut })
          TweenMax.to('.buttons-recenter', 0.6, { autoAlpha: 1, ease: Quad.easeInOut, onComplete: ()=>{
            this.addParticleLights({
              center: {
                x: 0, y: -0.5, z: 0 
              }
            }, 1000, 3, true)
            setTimeout(()=>{
              if (this.isBaseGrid) {
                this.setupLogo(this.baseGrid.position.x + 2.5, 3.5, this.baseGrid.position.z)
              } else {
                this.setupLogo(2.5, 3.5, -3)
              }
            }, 3000)
          }})
        }}
      )
    },
    render() {
      const delta = this.clock.getDelta();
      const time = this.clock.getElapsedTime();   

      if (this.placed) {
        this.animationMixers.forEach(mixer => {
          mixer?.update( delta );
        })

        // if (this.logo) {
        //   this.logo.lookAt(this.camera.position)
        // }
      }

      if (this.placed && this.geometryParticlesLights && this.geometryParticlesLights.attributes.size) {
        let sizes = this.geometryParticlesLights.attributes.size.array
        let sizeFactor = !this.placed ? 0 : 0.06
        for ( let p = 0; p < this.totalParticles; p ++ ) {
          sizes[p] = sizeFactor * ( 1 + Math.sin( sizeFactor * p + (time * 3) ) );
        }
        this.geometryParticlesLights.attributes.size.needsUpdate = true;
      }

      if (!this.placed && this.camera && this.raycaster && this.baseGrid && this.isBaseGrid) {
        let pos = new THREE.Vector3(0, 0, 0)
        let a = new THREE.Vector2( 0, -0.5 );
        
        this.raycaster.setFromCamera( a, this.camera )
        const intersects = this.raycaster.intersectObject( this.surface )

        if ( intersects.length == 1 && intersects[0].object == this.surface) {
          const intersect = intersects[0] 
          pos = intersect.point
          pos.y = 0.5
        }

        let distance = this.distanceVector(this.camera.position, pos)

        let scale = THREE.Math.mapLinear(distance, 10, 100, 0.4, 1.4);        
        // this.baseGrid.scale.set(scale, scale, scale);
        this.baseGrid.position.lerp(pos, 0.4)
        this.baseGrid.rotation.y = this.camera.rotation.y + Math.PI
      }

      if (this.placed && this.raycaster && this.isTap) {
        
        this.raycaster.setFromCamera( this.startTouchPosition, this.camera )
        const intersects = this.raycaster.intersectObjects( this.modelsInTheScene )
        if ( intersects.length == 1 && intersects[0].object != this.surface) {
          
          const intersect = intersects[0] 
          if (!this.brightAss) {
            gaTagEvent('REALIDADE AUMENTADA bradesco', 'Vagalume 2', 'clique acendeu');
            intersect.object.material.emissiveIntensity = 3;
            // intersect.object.material.envMapIntensity = 2;
            this.brightAss = true
          } else {
            gaTagEvent('REALIDADE AUMENTADA bradesco', 'Vagalume 2', 'clique apagou');
            intersect.object.material.emissiveIntensity = 0;
            // intersect.object.material.envMapIntensity = 1;
            // intersect.object.material.color.set( 0xff0000 );
            this.brightAss = false
          }

          if (window.navigator && window.navigator.vibrate) {
            window.navigator.vibrate(200);
          }

          this.isTap = false
        }

      }


      if (this.placed && this.particleGroup) this.particleGroup.tick( delta );
  
      if (this.composer) this.composer.render()
      if (this.sceneParts && this.cameraParts) {
        if (this.isDragging) {
          this.emitter.enable()
          this.mouseVector.unproject( this.cameraParts );
          this.emitter.position.value = this.emitter.position.value.set( this.mouseVector.x * this.cameraParts.fov, this.mouseVector.y * this.cameraParts.fov, 0 );
        } else {
          this.emitter.disable()
        }
        this.rendererParts.render(this.sceneParts, this.cameraParts)
      }

      requestAnimationFrame( this.render );
    },
    getPointsOnSymbol(image, size, space) {
      const canvas = document.createElement("canvas");
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext("2d");
      
      ctx.drawImage(image, 0, 0, size, size);

      const imgData = ctx.getImageData(0, 0, size, size);

      let points = [];
      let xStep = space;
      let yStep = space;
      for (var x = 0; x < size; x += xStep) {
          for (var y = 0; y < size; y += yStep) {
              var i = (y * size + x) * 4;

              if (imgData.data[i + 4] <= 0) {
                  continue;
              }

              points.push({
                  x: x - size / 2,
                  y: -(y - size / 2),
                  z: 0,
                  r: imgData.data[i] / 255,
                  g: imgData.data[i + 1] / 255,
                  b: imgData.data[i + 2] / 255,
                  a: imgData.data[i + 3] / 255,
              });
          }
      }
      return points;
    },
    distanceVector( v1, v2 ) {
        var dx = v1.x - v2.x;
        var dy = v1.y - v2.y;
        var dz = v1.z - v2.z;

        return Math.sqrt( dx * dx + dy * dy + dz * dz );
    },
    recenter(){
      XR8.XrController.recenter()
      if (this.logo) {
        setTimeout(()=>{
          this.logo.lookAt(this.camera.position)
        }, 1500)
      }
    },
    onResizeWindow() {
      if (this.camera) this.camera.updateProjectionMatrix();
      if (this.cameraParts) this.cameraParts.updateProjectionMatrix();
      
      const w = window.innerWidth
      const h = window.innerHeight
      if (this.renderer) this.renderer.setSize(w, h );
      if (this.rendererParts) this.rendererParts.setSize(w, h );
      if (this.composer) this.composer.setSize(w, h );
      TweenMax.set('.camera-feed', { width: w, height: h})
      
      if (this.fxaaPass) {
        const pixelRatio = this.renderer.getPixelRatio();
  
        this.fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / (w * pixelRatio );
        this.fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( h * pixelRatio );
      }

      window.scrollTo(0,0)

    },
  }
}
</script>

<style scoped lang="scss">
.experiment{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  // transition: background-color 0.6s linear;

  &.dark {
    background-color: rgba(0,0,0,0.6);
  }

  #canvas-renderer-parts {
    @include set-size(100%, 100vh);
    @include center(absolute);
    z-index: 1;
    overflow: hidden;
  }

  .tap-instructions{
    @include set-size(100%, 100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    // background-color: rgba(0,0,0,0.5);

    .tap-instructions__text{
      @include center(absolute);
      background-color: rgba(0,0,0,0.7);
      border-radius: 8px;
      color: #fff;
      text-align: center;
      font-family: $bold;
      padding: 10px 30px;
      margin: 0;
      font-size: 20px;
      line-height: 28px;
      min-width: 70vw;
    }

  }

  .buttons-container{
    @include set-size(100%, 12.5vh);
    position: fixed;
    bottom: 4vh;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    
    .buttons-recenter {
       @include set-size(32px, 32px);
       opacity: 0;
    }

    @include minWidth(500) {
      bottom: 15vh;
    }
  }

  .footer {
    @include set-size(100%, auto);
    padding: 5px 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 0;
    .footer__text {
      color: #fff;
      @include font-size(13);
      font-family: $regular;
      line-height: 18px;
      @include maxWidth(375){
        @include font-size(12);
      }

      @include minWidth(500) {
        @include font-size(14);
        line-height: 20px;
      }
    }
  }
}



</style>
