<template>
    <div class="desktop-view">
        <img class="desktop-view__logo" alt="Bradesco" :src="require('@/assets/images/logo-header.png')"/>
        <div class="desktop-view__content">
            <p class="desktop-view__text">
                PARA VER A REALIDADE AUMENTADA,<br>
                APONTE A CÂMERA DO CELULAR<br>
                PARA ESSE QRCODE.
            </p>
            <img class="desktop-view__qrcode"  :src="require('@/assets/images/qrcode.jpg')" alt="qr code">
        </div>
    </div>
</template>

<script>

export default {
    name: 'DesktopView',
    components: {},
    data(){
        return {
        }
    },
    created(){
        
    },
    methods: {
    }
}
</script>

<style lang="scss">
.desktop-view {
    @include set-size(100%, 100vh);
    background-color: #000;

    .desktop-view__logo {
        @include set-size(10vw, auto);
        // margin: 30px auto;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .desktop-view__content{
        @include set-size(68.5vw, auto);
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include center(absolute);

        .desktop-view__text {
            @include font-scale(1440, 1920, 32, 45);
            line-height: 54px;  
            font-family: $medium;
            text-align: center;
            color: #fff;

            @include maxWidth(1440) {
                line-height: 41px;
            }
        }

        .desktop-view__qrcode {
            width: 19.4vw;
        }

        @include maxWidth(1280) {
            // @include set-size(80vw, auto);

            .desktop-view__text {
                @include font-size(26);
                line-height: 34px;  
            }
        }
        @include maxWidth(1023) {
            display: block;

            .desktop-view__qrcode {
                width: 25vw;
            }

             .desktop-view__text {
                @include font-size(16);
                line-height: 22px;  
            }
        }
    }
}
</style>
