export const vshader = `
    attribute float size;

    varying vec3 vColor;

    void main() {

        vColor = color;

        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );

        gl_PointSize = size * ( 300.0 / -mvPosition.z );

        gl_Position = projectionMatrix * mvPosition;

    }
`

export const fshader = `
    uniform sampler2D pointTexture;

    varying vec3 vColor;

    void main() {

        // gl_FragColor = vec4( vColor, 1.0 );

        // gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
        gl_FragColor = texture2D( pointTexture, gl_PointCoord );

    }
`
const THREE = require('three');
export const particleMaterial = (particle, depthTest) => {
    const uniforms = {
        pointTexture: { value: particle }
    };
    const material	= new THREE.ShaderMaterial( {
        uniforms: uniforms,
        vertexShader: vshader,
        fragmentShader: fshader,
        blending: THREE.AdditiveBlending,
        depthTest: depthTest ? depthTest : false,
        depthWrite: false,
        transparent: true,
        vertexColors: true

    } );
    return material;
}